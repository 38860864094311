import type { LAYER_MAPPING } from '../types/layer'

const LAYERS: LAYER_MAPPING = {
    radar: {
        url: 'https://nowcoast.noaa.gov/arcgis/services/nowcoast/radar_meteo_imagery_nexrad_time/MapServer/WMSServer',
        service: 'radar_meteo_imagery_nexrad_time',
        layers: [
            {
                id: '1',
                arcgisId: '3',
                name: 'Base Reflectivity',
                max: 0,
                interval: 1,
                start: 0,
            }
        ],
        name: 'Radar',
        id: 'radar',
        forecast: false,
    },
    satellite: {
        url: 'https://nowcoast.noaa.gov/arcgis/services/nowcoast/sat_meteo_imagery_time/MapServer/WMSServer',
        service: 'sat_meteo_imagery_time',
        layers: [
            {
                name: 'Global SWIR',
                id: '1',
                max: 0,
                interval: 1,
                start: 0,
            },
            {
                name: 'Global LWIR',
                id: '5',
                max: 0,
                interval: 1,
                start: 0,
            },
            {
                name: 'Global V',
                id: '9',
                max: 0,
                interval: 1,
                start: 0,
            },
            {
                name: 'GOES SWIR',
                id: '13',
                max: 0,
                interval: 1,
                start: 0,
            },
            {
                name: 'GOES LWIR',
                id: '17',
                max: 0,
                interval: 1,
                start: 0,
            },
            {
                name: 'GOES V',
                id: '25',
                max: 0,
                interval: 1,
                start: 0,
            },
            {
                name: 'Water Vapor Imagery',
                id: '21',
                max: 0,
                interval: 1,
                start: 0,
            }
        ],
        name: 'Satellite',
        id: 'satellite',
        forecast: false,
    },
    rtma: {
        url: 'https://nowcoast.noaa.gov/arcgis/services/nowcoast/analysis_meteohydro_sfc_rtma_time/MapServer/WMSServer',
        service: 'analysis_meteohydro_sfc_rtma_time',
        layers: [
            {
                name: 'Temperature',
                arcgisId: '11',
                id: '17',
                max: 0,
                interval: 1,
                start: 0,
            },
            {
                name: 'Wind Speed',
                id: '9',
                arcgisId: '19',
                max: 0,
                interval: 1,
                start: 0,
            },
            {
                name: 'Dew Point',
                arcgisId: '15',
                id: '13',
                max: 0,
                interval: 1,
                start: 0,
            },
            {
                name: 'Precip',
                arcgisId: '7',
                id: '21',
                max: 0,
                interval: 1,
                start: 0,
            },
            {
                name: 'Wind Velo',
                arcgisId: '3',
                id: '25',
                max: 0,
                interval: 1,
                start: 0,
            },
            {
                name: 'Visibility',
                arcgisId: '27',
                id: '1',
                max: 0,
                interval: 1,
                start: 0,
            }
        ],
        name: 'Current',
        id: 'rtma',
        forecast: false,
    },
    forecast: {
        url: 'https://nowcoast.noaa.gov/arcgis/services/nowcoast/forecast_meteoceanhydro_sfc_ndfd_time/MapServer/WMSServer',
        service: 'forecast_meteoceanhydro_sfc_ndfd_time',
        layers: [
            {
                name: 'Temperature',
                arcgisId: '31',
                id: '25',
                max: 36,
                interval: 1,
                start: 1,
            },
            {
                name: 'Feels Like',
                arcgisId: '39',
                id: '17',
                max: 36,
                interval: 1,
                start: 1,
            },
            {
                name: 'Dew Point',
                arcgisId: '35',
                id: '21',
                max: 36,
                interval: 1,
                start: 1,
            },
            {
                name: 'Humidity',
                arcgisId: '43',
                id: '13',
                max: 36,
                interval: 1,
                start: 1,
            },
            {
                name: 'Chance Precip',
                arcgisId: '23',
                id: '33',
                max: 72,
                interval: 12,
                start: 12,
            },
            {
                name: 'Quant Precip',
                arcgisId: '15',
                id: '41',
                max: 72,
                interval: 6,
                start: 6,
            },
            {
                name: 'Sky Cover %',
                arcgisId: '27',
                id: '29',
                max: 36,
                interval: 1,
                start: 1,
            },
            {
                name: 'Wind Speed',
                arcgisId: '47',
                id: '9',
                max: 36,
                interval: 1,
                start: 1,
            },
            {
                name: 'Wind Gusts',
                arcgisId: '51',
                id: '5',
                max: 36,
                interval: 1,
                start: 1,
            },
        ],
        name: 'Forecast',
        id: 'forecast',
        forecast: true
    },
    severe: {
        url: 'https://nowcoast.noaa.gov/arcgis/services/nowcoast/guidance_natlcenters_meteoceanhydro_outlooks_time/MapServer/WMSServer',
        service: 'guidance_natlcenters_meteoceanhydro_outlooks_time',
        layers: [
            {
                id: '9',
                arcgisId: '3',
                name: 'Severe Outlook .',
                max: 0,
                interval: 1,
                start: 0,
            }
        ],
        name: 'Severe Outlook',
        id: 'severe',
        forecast: false,
    }
}

export default LAYERS
