const dark = {
    text: '#fff',
    body: '#444',
    bg: '#444',
    fg: '#fff',
    line: '#fff',
    map: "mapbox://styles/mapbox/dark-v10",
    altRow: '#777',
}

export default dark
